import React from "react"

class ContactForm extends React.Component {
  state = {
    name: "",
    phone: "",
    email: "",
    message: ""
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  render() { return (
    <form name="contact" method="post" action="#" data-netlify="true">
      <input type="hidden" name="form-name" value="contact" />
      <label>
        Name
          <input
          type="text"
          name="name"
          value={this.state.name}
          onChange={this.handleInputChange}
        />
      </label>
      <label>
        Phone
          <input
          type="text"
          name="phone"
          value={this.state.phone}
          onChange={this.handleInputChange}
        />
      </label>
      <label>
        Email
          <input
          type="text"
          name="email"
          value={this.state.email}
          onChange={this.handleInputChange}
        />
      </label>
      <label>
        Message
          <input
          type="text"
          name="message"
          value={this.state.message}
          onChange={this.handleInputChange}
        />
      </label>
      <button type="submit">Send</button>
    </form>
  )}
}

export default ContactForm